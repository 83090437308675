@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #131313;
}

@font-face {
  font-family: "calligraphitti";
  src: url("../public/calligraphitti.ttf");
}